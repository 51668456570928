export const ROLE_LABEL_MAP: Record<string, string> = {
  ADMIN: 'ADMIN',
  USER: 'PARTICIPANTS',
  DESIGNER: 'DESIGNER'
}

export const parseUsersRaw = (rawValue: string): any[] => {
  const users = []
  const rows = rawValue.split(/\r?\n/)
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const cols = row.split(/\s*,\s*/)
    const email = cols[0]
    const username = email.substring(0, email.indexOf('@'))
    let role = cols[1].toUpperCase()
    if (role === 'PARTICIPANT') {
      role = 'USER'
    }
    const roles = [role]
    const user = {
      ext: {},
      username,
      email,
      enabled: true,
      title: null,
      firstName: null,
      lastName: null,
      profilePhoto: null,
      primaryPhone: null,
      roles,
      expertise: [],
      comments: null
    }
    users.push(user)
  }
  return users
}
