
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const usersTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (usersTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      const usersRawValue = getSelectionRaw()
      if (usersRawValue?.length) {
        emit(EVENTS.OK, usersRawValue)
      }
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      usersTextAreaWrapper
    }
  }
})
