import { render } from "./UserList.vue?vue&type=template&id=3366b2e6"
import script from "./UserList.vue?vue&type=script&lang=ts"
export * from "./UserList.vue?vue&type=script&lang=ts"

import "./UserList.vue?vue&type=style&index=0&id=3366b2e6&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3366b2e6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3366b2e6', script)) {
    api.reload('3366b2e6', script)
  }
  
  module.hot.accept("./UserList.vue?vue&type=template&id=3366b2e6", () => {
    api.rerender('3366b2e6', render)
  })

}

script.__file = "src/components/user/UserList.vue"

export default script