import { render } from "./index.vue?vue&type=template&id=f4b50242&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"

import "./index.vue?vue&type=style&index=0&id=f4b50242&lang=stylus&scoped=true"
script.render = render
script.__scopeId = "data-v-f4b50242"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4b50242"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4b50242', script)) {
    api.reload('f4b50242', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=f4b50242&scoped=true", () => {
    api.rerender('f4b50242', render)
  })

}

script.__file = "src/components/loading/index.vue"

export default script