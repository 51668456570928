<template>
  <user-list />
  <Loading :is-show="isLoading" :tips="loadingPromptText" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { NavigationGuard } from 'vue-router'

import Loading from '@/components/loading/index.vue'
import UserList from '@/components/user/UserList.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions/user'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'

const store = useStore()
const loadPage: NavigationGuard = async (to, from, next) => {
  // Get first page of user list
  await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
    params: { page: API_DEFAULT_PAGEABLE_PARAMS.page, size: API_DEFAULT_PAGEABLE_PARAMS.size }
  })
  next()
}

export default defineComponent({
  components: {
    UserList,
    Loading
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  setup() {
    const isGettingUser = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_GETTING])
    const isCreatingUser = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_CREATING])
    const isUpdatingUser = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_UPDATING])
    const isDeletingUser = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_DELETING])
    const userLoadingPromptText = computed(
      () => store.state[ModuleNames.USER][UserStateEnum.LOADING_PROMPT_TEXT]
    )

    const isLoading = computed(
      () =>
        isGettingUser.value || isCreatingUser.value || isUpdatingUser.value || isDeletingUser.value
    )
    const loadingPromptText = computed(() => userLoadingPromptText.value || '')
    return { isLoading, loadingPromptText }
  }
})
</script>
