<template>
  <a-modal
    title="Batch Users for Keycloak"
    width="800px"
    :visible="isVisible"
    @ok="ok"
    @cancel="cancel"
  >
    <textarea
      ref="usersTextAreaWrapper"
      style="width: 100%; height: 450px"
      placeholder="Paste your users here (email,role)"
      name="sz-users-textarea"
      spellcheck="false"
    />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const usersTextAreaWrapper: Ref<HTMLElement | null> = ref(null)
    const getSelectionRaw = () => (usersTextAreaWrapper.value as HTMLTextAreaElement)?.value || ''

    const ok = () => {
      const usersRawValue = getSelectionRaw()
      if (usersRawValue?.length) {
        emit(EVENTS.OK, usersRawValue)
      }
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      ok,
      cancel,
      usersTextAreaWrapper
    }
  }
})
</script>
