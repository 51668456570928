
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    tips: {
      default: 'Loading...',
      type: String
    }
  }
})
