import { render } from "./UserBatchImport.vue?vue&type=template&id=18cf7c60"
import script from "./UserBatchImport.vue?vue&type=script&lang=ts"
export * from "./UserBatchImport.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "18cf7c60"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('18cf7c60', script)) {
    api.reload('18cf7c60', script)
  }
  
  module.hot.accept("./UserBatchImport.vue?vue&type=template&id=18cf7c60", () => {
    api.rerender('18cf7c60', render)
  })

}

script.__file = "src/components/user/UserBatchImport.vue"

export default script