<template>
  <div v-if="isShow" class="spin-container">
    <a-spin size="large" :tip="tips" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    tips: {
      default: 'Loading...',
      type: String
    }
  }
})
</script>

<style lang="stylus" scoped>
.spin-container
  position fixed
  z-index 10000
  backdrop-filter blur(2px)
  -webkit-backdrop-filter blur(2px)
  top 0
  bottom 0
  left 0
  right 0
  .ant-spin,.ant-spin-lg,.ant-spin-spinning,.ant-spin-text
    margin 40vh 50vw
    white-space nowrap !important
</style>
