
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons-vue'
import { FileImport, UserPlus } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import { TableState } from 'ant-design-vue/es/table/interface'
import { clone } from 'ramda'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  Ref,
  ref,
  toRaw,
  watch
} from 'vue'
import { useRequest } from 'vue-request'

import ActionButton from '@/components/common/ActionButton.vue'
import { parseUsersRaw, ROLE_LABEL_MAP } from '@/components/user/user'
import UserForm from '@/components/user/UserForm.vue'
import { API_DEFAULT_PAGEABLE_PARAMS } from '@/constants/api'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { tableScrollY } from '@/libs/utils'
import { createUsers } from '@/services/api/user'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions/user'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { UserStateEnum } from '@/store/enums/states/user'
import { vuexActions } from '@/store/util'
import { cssIcon } from '@/styles/common'
import { User } from '@/types'

import UserBatchImport, { EVENTS as IMPORT_EVENTS } from './UserBatchImport.vue'

type Pagination = TableState['pagination']
type DataItem = User & { key?: string }

const ROLE_TAG_COLOR: Record<string, string> = {
  [DB_ENUM_VALUES.USER.ROLES.ADMIN]: 'red',
  [DB_ENUM_VALUES.USER.ROLES.USER]: 'blue'
} as const

const COLUMNS = [
  {
    title: 'Email',
    dataIndex: [DB_FIELDS.USER.EMAIL],
    key: DB_FIELDS.USER.EMAIL,
    fixed: 'left',
    width: 220
  },
  {
    dataIndex: [DB_FIELDS.USER.USERNAME],
    key: DB_FIELDS.USER.USERNAME,
    title: 'Username',
    fixed: 'left',
    width: 150
  },
  {
    title: 'Roles',
    dataIndex: [DB_FIELDS.USER.ROLES],
    key: DB_FIELDS.USER.ROLES,
    width: 130,
    slots: { customRender: 'roles' }
  },
  {
    title: 'Enabled',
    dataIndex: [DB_FIELDS.USER.ENABLED],
    key: DB_FIELDS.USER.ENABLED,
    width: 70,
    slots: { customRender: 'enabled' }
  },
  {
    title: 'Title',
    dataIndex: [DB_FIELDS.USER.TITLE],
    key: DB_FIELDS.USER.TITLE,
    width: 60
  },
  {
    title: 'First Name',
    dataIndex: [DB_FIELDS.USER.FIRST_NAME],
    key: DB_FIELDS.USER.FIRST_NAME,
    width: 120
  },
  {
    title: 'Last Name',
    dataIndex: [DB_FIELDS.USER.LAST_NAME],
    key: DB_FIELDS.USER.LAST_NAME,
    width: 120
  },
  {
    title: 'Primary Phone',
    dataIndex: [DB_FIELDS.USER.PRIMARY_PHONE],
    key: DB_FIELDS.USER.PRIMARY_PHONE,
    width: 120,
    customRender: ({ text }: { text: string | null }) => {
      if (text) return `+61 ${text}`
      return text
    }
  },
  {
    title: 'Expertise',
    dataIndex: [DB_FIELDS.USER.EXPERTISE],
    key: DB_FIELDS.USER.EXPERTISE,
    width: 250,
    slots: { customRender: 'expertise' }
  },
  {
    title: 'Comments',
    dataIndex: [DB_FIELDS.USER.COMMENTS],
    key: DB_FIELDS.USER.COMMENTS,
    width: 250
  },
  {
    title: 'Actions',
    fixed: 'right',
    width: 150,
    slots: { customRender: 'actions' }
  }
]

const TABLE_HEADER_HEIGHT = 39
const TABLE_PAGNATION_HEIGHT = 56

export default defineComponent({
  components: {
    ActionButton,
    UserPlus,
    EditTwoTone,
    UserForm,
    DeleteTwoTone,
    UserBatchImport,
    FileImport,
    Icon
  },
  setup() {
    const store = useStore()
    const isBatchImportVisible: Ref<boolean> = ref(false)
    const currentUser = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.USER])
    const isUpdating = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_UPDATING])
    const isDeleting = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_DELETING])
    const error = computed(() => store.state[ModuleNames.USER][UserStateEnum.ERROR])
    const totalUsers = computed(() => {
      return store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.totalElements
    })

    const disabled = computed(() => isUpdating.value || isDeleting.value)

    const dataSource = computed(() => {
      const currentUserList = store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.content
      return currentUserList?.map((each: User) => ({
        ...toRaw(each),
        key: each?.id
      }))
    })
    const currentPage = ref<number>(API_DEFAULT_PAGEABLE_PARAMS.page + 1)
    const isUserFormModalVisible = ref(false)
    const selectedUser = ref<User | undefined>()
    const pagination = computed(() => ({
      total: totalUsers.value,
      current: currentPage.value,
      defaultPageSize: API_DEFAULT_PAGEABLE_PARAMS.size
    }))
    const tableWrapper = ref(null)
    const scrollY: Ref<null | number> = ref(null)

    /**
     * Handle display user form model
     **/
    const onVisibleChange = (visible: boolean) => {
      isUserFormModalVisible.value = visible
    }

    const onCreateUser = () => {
      selectedUser.value = undefined
      isUserFormModalVisible.value = true
    }

    const onEditUser = (record: DataItem) => {
      const user = clone(record)
      if (user.key) {
        delete user.key
      }
      selectedUser.value = user as User
      isUserFormModalVisible.value = true
    }

    /**
     * Handle enable or disable user
     * @param record - user data
     * @param value - user enable field value
     **/
    const onUserEnableChanged = async (record: User, value: boolean) => {
      try {
        const id = record?.[DB_FIELDS.USER.ID]
        const user = {
          [DB_FIELDS.USER.ENABLED]: value
        }
        await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.UPDATE_USER), {
          id,
          user
        })
        if (value) {
          message.success(
            MESSAGE.USER_ENABLED_SUCCESS({ username: record[DB_FIELDS.USER.USERNAME] })
          )
        } else {
          message.success(
            MESSAGE.USER_DISABLED_SUCCESS({ username: record[DB_FIELDS.USER.USERNAME] })
          )
        }
      } catch (err) {
        // pass
      }
    }

    /**
     * Delete user
     * @param user
     */
    const onDeleteUser = async (user: User) => {
      store.dispatch(
        vuexActions(ModuleNames.USER, UserActionEnum.DELETE_USER),
        user[DB_FIELDS.USER.ID]
      )
    }

    /**
     * Get users
     **/
    const getUsers = async () => {
      await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
        params: { page: pagination.value.current - 1, size: API_DEFAULT_PAGEABLE_PARAMS.size }
      })
    }

    /**
     * Handle user list table change
     **/
    const handleTableChange = async (pag: Pagination) => {
      if (
        pag &&
        Object.prototype.hasOwnProperty.call(pag, 'current') &&
        typeof pag.current === 'number'
      ) {
        currentPage.value = pag.current
      }
      await getUsers()
    }

    const onResize = () => {
      const offset = TABLE_HEADER_HEIGHT + TABLE_PAGNATION_HEIGHT
      const y = tableScrollY(tableWrapper.value, offset)
      if (scrollY.value !== y) {
        scrollY.value = y
      }
    }

    watch([isDeleting, error], ([isDeleting, error], [prevIsDeleting]) => {
      if (prevIsDeleting && !isDeleting) {
        if (!error) {
          message.success(MESSAGE.USER_DELETED_SUCCESS)
        }
      }
    })

    /**
     * Vue life cycle
     */
    onBeforeMount(() => {
      window.addEventListener('resize', onResize)
    })

    onMounted(() => {
      onResize()
    })

    onUpdated(() => {
      onResize()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    const { run: runCreateUsers } = useRequest(createUsers, {
      manual: true
    })
    const importBulk = async (text: string) => {
      isBatchImportVisible.value = false
      const users = parseUsersRaw(text)
      if (users) {
        await runCreateUsers(users)
      }
    }

    const importBatch = () => {
      isBatchImportVisible.value = true
    }

    return {
      importBatch,
      importBulk,
      isBatchImportVisible,
      ROLE_LABEL_MAP,
      COLUMNS,
      DB_FIELDS,
      ROLE_TAG_COLOR,
      currentUser,
      dataSource,
      disabled,
      handleTableChange,
      isUserFormModalVisible,
      onCreateUser,
      onDeleteUser,
      onEditUser,
      onUserEnableChanged,
      onVisibleChange,
      pagination,
      scrollY,
      selectedUser,
      tableWrapper,
      IMPORT_EVENTS,
      cssIcon
    }
  }
})
