<template>
  <a-modal
    :cancel-button-props="{ disabled: formDisabled }"
    :closable="!formDisabled"
    :mask-closable="!formDisabled"
    :ok-button-props="{ loading: formDisabled }"
    :title="title"
    :visible="visible"
    :width="600"
    class="sz-user-form-modal"
    @cancel="onCancel"
    @ok="onSubmit"
  >
    <a-form ref="formRef" :label-col="{ span: 5 }" :model="formState" :wrapper-col="{ span: 19 }">
      <a-form-item
        :label="FORM_ITEMS.EMAIL.LABEL"
        :name="FORM_ITEMS.EMAIL.NAME"
        :rules="isSelf ? null : FORM_RULES[FORM_ITEMS.EMAIL.NAME]"
      >
        <a-input v-model:value="formState[FORM_ITEMS.EMAIL.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item
        :label="FORM_ITEMS.USERNAME.LABEL"
        :name="FORM_ITEMS.USERNAME.NAME"
        :rules="FORM_RULES[FORM_ITEMS.USERNAME.NAME]"
      >
        <a-input
          v-model:value="formState[FORM_ITEMS.USERNAME.NAME]"
          class="sz-user-form-username-input"
          :disabled="formDisabled || !isCreate || usernameSameAsEmail"
        />
        <!-- <a-checkbox
          v-model:checked="usernameSameAsEmail"
          class="sz-user-form-username-checkbox"
          v-if="isCreate"
        >
          Same as Email
        </a-checkbox> -->
      </a-form-item>
      <a-form-item
        :label="FORM_ITEMS.PASSWORD.LABEL"
        :name="FORM_ITEMS.PASSWORD.NAME"
        :rules="updatePassword || isCreate ? FORM_RULES[FORM_ITEMS.PASSWORD.NAME] : null"
      >
        <a-input-password
          v-show="updatePassword || isCreate"
          v-model:value="formState[FORM_ITEMS.PASSWORD.NAME]"
          :disabled="formDisabled"
        />
        <a-button
          v-show="!isCreate && !updatePassword"
          type="primary"
          size="small"
          @click="setUpdatePassword"
        >
          Update Password
        </a-button>
      </a-form-item>
      <a-form-item
        :label-col="{ span: 5 }"
        :label="FORM_ITEMS.ROLES.LABEL"
        :name="FORM_ITEMS.ROLES.NAME"
        :wrapper-col="{ span: 10 }"
        :rules="FORM_RULES[FORM_ITEMS.ROLES.NAME]"
      >
        <a-select
          v-model:value="formState[FORM_ITEMS.ROLES.NAME]"
          mode="multiple"
          :options="USER_ROLE_OPTIONS"
          :disabled="formDisabled"
        />
      </a-form-item>
      <a-form-item
        :label="FORM_ITEMS.ENABLED.LABEL"
        :name="FORM_ITEMS.ENABLED.NAME"
        :rules="FORM_RULES[FORM_ITEMS.ENABLED.NAME]"
      >
        <a-switch
          v-model:checked="formState[FORM_ITEMS.ENABLED.NAME]"
          checked-children="Enabled"
          un-checked-children="Disabled"
          :disabled="formDisabled || isSelf"
        />
      </a-form-item>
      <a-form-item :label="FORM_ITEMS.TITLE.LABEL" :name="FORM_ITEMS.TITLE.NAME">
        <a-input v-model:value="formState[FORM_ITEMS.TITLE.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item :label="FORM_ITEMS.FIRST_NAME.LABEL" :name="FORM_ITEMS.FIRST_NAME.NAME">
        <a-input v-model:value="formState[FORM_ITEMS.FIRST_NAME.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item :label="FORM_ITEMS.LAST_NAME.LABEL" :name="FORM_ITEMS.LAST_NAME.NAME">
        <a-input v-model:value="formState[FORM_ITEMS.LAST_NAME.NAME]" :disabled="formDisabled" />
      </a-form-item>
      <a-form-item
        :label="FORM_ITEMS.PRIMARY_PHONE.LABEL"
        :name="FORM_ITEMS.PRIMARY_PHONE.NAME"
        :rules="FORM_RULES[FORM_ITEMS.PRIMARY_PHONE.NAME]"
      >
        <a-input
          v-model:value="formState[FORM_ITEMS.PRIMARY_PHONE.NAME]"
          addon-before="+61"
          :disabled="formDisabled"
        />
      </a-form-item>
      <a-form-item
        :label="FORM_ITEMS.PROFILE_PHOTO.LABEL"
        :name="FORM_ITEMS.PROFILE_PHOTO.NAME"
        :rules="FORM_RULES[FORM_ITEMS.PROFILE_PHOTO.NAME]"
      >
        <a-upload
          v-model:fileList="formState[FORM_ITEMS.PROFILE_PHOTO.NAME]"
          :before-upload="onProfileImageBeforeUpload"
          :disabled="formDisabled"
          :show-upload-list="false"
          accept=".jpeg,.png,.jpg,.gif"
          list-type="picture"
          @change="onProfileImageChange"
        >
          <div class="sz-user-form-profile-photo-wrapper">
            <a-avatar :size="140" :src="profileImageUrl" class="sz-user-form-profile-photo" />
            <div class="sz-user-form-profile-photo-controls">
              <plus-outlined class="sz-user-form-profile-photo-icon" />
              <div class="sz-user-form-profile-photo-text">Upload</div>
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label="FORM_ITEMS.EXPERTISE.LABEL" :name="FORM_ITEMS.EXPERTISE.NAME">
        <a-select
          v-model:value="formState[FORM_ITEMS.EXPERTISE.NAME]"
          :disabled="formDisabled"
          :filter-option="filterExpertiseOption"
          :options="expertiseOptions"
          :show-arrow="false"
          :token-separators="[',']"
          mode="multiple"
          @search="handleExpertiseSearch"
        />
      </a-form-item>
      <a-form-item :label="FORM_ITEMS.COMMENTS.LABEL" :name="FORM_ITEMS.COMMENTS.NAME">
        <a-textarea
          v-model:value="formState[FORM_ITEMS.COMMENTS.NAME]"
          :disabled="formDisabled"
          auto-size
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { clone } from 'ramda'
import { computed, defineComponent, PropType, reactive, Ref, ref, toRaw, watch } from 'vue'

import { DEFAULT_PROFILE_PHOTO_URL } from '@/constants/components'
import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { maxFileSize } from '@/libs/formValidate'
import { filterExpertiseOption, genExpertiseOptions, getBase64 } from '@/libs/utils'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions/user'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions } from '@/store/util'
import { User, UserForm } from '@/types'

import { ROLE_LABEL_MAP } from './user'

interface FileInfo {
  file: File
  fileList: File[]
}

type FormState = Omit<UserForm, 'profilePhoto'> & {
  [DB_FIELDS.USER.PROFILE_PHOTO]: File[]
}
export const EVENTS = {
  SET_VISIBLE: 'setVisible'
} as const

const FORM_ITEMS = {
  USERNAME: {
    NAME: DB_FIELDS.USER.USERNAME,
    LABEL: 'Username'
  },
  PASSWORD: {
    NAME: DB_FIELDS.USER.PASSWORD,
    LABEL: 'Password'
  },
  EMAIL: {
    NAME: DB_FIELDS.USER.EMAIL,
    LABEL: 'Email'
  },
  ENABLED: {
    NAME: DB_FIELDS.USER.ENABLED,
    LABEL: 'Enabled'
  },
  TITLE: {
    NAME: DB_FIELDS.USER.TITLE,
    LABEL: 'Title'
  },
  FIRST_NAME: {
    NAME: DB_FIELDS.USER.FIRST_NAME,
    LABEL: 'First Name'
  },
  LAST_NAME: {
    NAME: DB_FIELDS.USER.LAST_NAME,
    LABEL: 'Last Name'
  },
  PROFILE_PHOTO: {
    NAME: DB_FIELDS.USER.PROFILE_PHOTO,
    LABEL: 'Profile Photo'
  },
  PRIMARY_PHONE: {
    NAME: DB_FIELDS.USER.PRIMARY_PHONE,
    LABEL: 'Primary Phone'
  },
  ROLES: {
    NAME: DB_FIELDS.USER.ROLES,
    LABEL: 'Roles'
  },
  EXPERTISE: {
    NAME: DB_FIELDS.USER.EXPERTISE,
    LABEL: 'Expertise'
  },
  COMMENTS: {
    NAME: DB_FIELDS.USER.COMMENTS,
    LABEL: 'Comments'
  }
} as const

const DEFAULT_FORM_STATE: FormState = {
  [DB_FIELDS.USER.EXT]: {},
  [FORM_ITEMS.USERNAME.NAME]: '',
  [FORM_ITEMS.PASSWORD.NAME]: '',
  [FORM_ITEMS.EMAIL.NAME]: '',
  [FORM_ITEMS.ENABLED.NAME]: false,
  [FORM_ITEMS.TITLE.NAME]: null,
  [FORM_ITEMS.FIRST_NAME.NAME]: null,
  [FORM_ITEMS.LAST_NAME.NAME]: null,
  [FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
  [FORM_ITEMS.PRIMARY_PHONE.NAME]: null,
  [FORM_ITEMS.ROLES.NAME]: [],
  [FORM_ITEMS.EXPERTISE.NAME]: [],
  [FORM_ITEMS.COMMENTS.NAME]: null
}

const FORM_RULES = {
  [FORM_ITEMS.USERNAME.NAME]: [
    {
      required: true,
      message: 'Please enter the username'
    },
    {
      pattern: new RegExp('^[a-z0-9._-]{3,}$'),
      message:
        'Only lowercase alphabet, number and special characters("_" and "-") are allowed. The minimum length is 3.',
      type: 'string'
    }
  ],
  [FORM_ITEMS.PASSWORD.NAME]: [
    {
      required: true,
      message: 'Please enter the password'
    }
  ],
  [FORM_ITEMS.EMAIL.NAME]: [
    {
      required: true,
      message: 'Please enter the email'
    },
    {
      message: 'Please enter a valid email',
      type: 'email',
      trigger: 'blur'
    }
  ],
  [FORM_ITEMS.ROLES.NAME]: [
    {
      required: true,
      message: 'Please select roles',
      type: 'array'
    }
  ],
  [FORM_ITEMS.ENABLED.NAME]: [
    {
      required: true,
      message: '',
      type: 'boolean'
    }
  ],
  [FORM_ITEMS.PROFILE_PHOTO.NAME]: [
    {
      validator: maxFileSize({
        maxSize: 100 * 1024,
        errorMessage: 'Maxium file size is 100Kb',
        multiple: false
      })
    }
  ],
  [FORM_ITEMS.PRIMARY_PHONE.NAME]: [
    {
      pattern: new RegExp('^[2-478](?:[ -]?[0-9]){8}$'),
      message: 'Please enter a valid phone number',
      trigger: 'blur',
      type: 'string'
    }
  ]
} as const

const USER_ROLE_OPTIONS = Object.values(DB_ENUM_VALUES.USER.ROLES).map((el, idx) => ({
  value: el,
  label: ROLE_LABEL_MAP[el] || el,
  key: idx
}))

const initFormData = (user: User | undefined) => {
  if (user) {
    return Object.assign(clone(DEFAULT_FORM_STATE), {
      [DB_FIELDS.USER.EXT]: user[DB_FIELDS.USER.EXT],
      [FORM_ITEMS.USERNAME.NAME]: user[DB_FIELDS.USER.USERNAME],
      [FORM_ITEMS.PASSWORD.NAME]: user[DB_FIELDS.USER.PASSWORD],
      [FORM_ITEMS.EMAIL.NAME]: user[DB_FIELDS.USER.EMAIL],
      [FORM_ITEMS.TITLE.NAME]: user[DB_FIELDS.USER.TITLE],
      [FORM_ITEMS.ENABLED.NAME]: user[DB_FIELDS.USER.ENABLED],
      [FORM_ITEMS.FIRST_NAME.NAME]: user[DB_FIELDS.USER.FIRST_NAME],
      [FORM_ITEMS.LAST_NAME.NAME]: user[DB_FIELDS.USER.LAST_NAME],
      [FORM_ITEMS.PROFILE_PHOTO.NAME]: [],
      [FORM_ITEMS.PRIMARY_PHONE.NAME]:
        user[DB_FIELDS.USER.PRIMARY_PHONE] || DEFAULT_FORM_STATE[FORM_ITEMS.PRIMARY_PHONE.NAME],
      [FORM_ITEMS.ROLES.NAME]:
        user[DB_FIELDS.USER.ROLES] || DEFAULT_FORM_STATE[FORM_ITEMS.ROLES.NAME],
      [FORM_ITEMS.EXPERTISE.NAME]:
        user[DB_FIELDS.USER.EXPERTISE] || DEFAULT_FORM_STATE[FORM_ITEMS.EXPERTISE.NAME],
      [FORM_ITEMS.COMMENTS.NAME]:
        user[DB_FIELDS.USER.COMMENTS] || DEFAULT_FORM_STATE[FORM_ITEMS.COMMENTS.NAME]
    })
  }
  return clone(DEFAULT_FORM_STATE)
}

export default defineComponent({
  components: {
    PlusOutlined
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    user: {
      type: Object as PropType<User | undefined>,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const isSelf = computed(
      () =>
        store.state[ModuleNames.AUTH][AuthStateEnum.USER]?.[DB_FIELDS.USER.ID] ===
        props.user?.[DB_FIELDS.USER.ID]
    )
    const isCreate = computed(() => !props.user)
    const title = computed(() => (props.user ? 'Edit User' : 'Create User'))
    const formRef = ref()
    const formDisabled = ref<boolean>(false)
    const formState = reactive(initFormData(props.user))
    const usernameSameAsEmail = ref<boolean>(false)
    const updatePassword = ref<boolean>(false)
    const profileImageUrl = ref(
      props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
    )
    const expertiseSearchText: Ref<string | null> = ref('')
    const expertiseOptions = computed(() =>
      genExpertiseOptions(formState[FORM_ITEMS.EXPERTISE.NAME], expertiseSearchText.value)
    )

    const onProfileImageBeforeUpload = () => false
    const onProfileImageChange = async (info: FileInfo) => {
      try {
        const { file } = info
        const url = await getBase64(file)
        profileImageUrl.value = url
      } catch (err) {
        const errorMessage = err.message || 'Failed to read image.'
        message.error(errorMessage)
      }
    }
    const onCancel = () => {
      emit(EVENTS.SET_VISIBLE, false)
    }

    const onSubmit = async () => {
      formDisabled.value = true
      if (formRef.value) {
        try {
          await formRef.value.validate()
        } catch (err) {
          formDisabled.value = false
          const firstErrorFieldName =
            err.errorFields[0].name.length > 1 ? [err.errorFields[0].name] : err.errorFields[0].name
          formRef.value.scrollToField(firstErrorFieldName, { behavior: 'smooth' })
          return
        }
      }
      let data = {} as UserForm
      // update profile photo field with base64 url
      if (formState[FORM_ITEMS.PROFILE_PHOTO.NAME].length > 0) {
        data = Object.assign({}, toRaw(formState), {
          [FORM_ITEMS.PROFILE_PHOTO.NAME]: profileImageUrl.value
        })
      } else {
        data = Object.assign({}, toRaw(formState), {
          [FORM_ITEMS.PROFILE_PHOTO.NAME]: null
        })
      }
      if (props.user) {
        if (!data[FORM_ITEMS.PASSWORD.NAME]) {
          delete data[FORM_ITEMS.PASSWORD.NAME]
        }
        try {
          await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.UPDATE_USER), {
            id: props.user[DB_FIELDS.USER.ID],
            user: data
          })
          message.success(MESSAGE.USER_UPDATED_SUCCESS({ username: data[DB_FIELDS.USER.USERNAME] }))
        } catch (err) {
          formDisabled.value = false
          return
        }
      } else {
        try {
          await store.dispatch(
            vuexActions(ModuleNames.USER, UserActionEnum.CREATE_USER),
            data as UserForm
          )
          message.success(MESSAGE.USER_CREATED_SUCCESS)
        } catch (err) {
          formDisabled.value = false
          return
        }
      }
      emit(EVENTS.SET_VISIBLE, false)
      formDisabled.value = false
      if (!props.user) {
        formRef.value.resetFields()
        profileImageUrl.value = DEFAULT_PROFILE_PHOTO_URL
      }
    }

    const setUpdatePassword = () => (updatePassword.value = true)

    const handleExpertiseSearch = (value: string) => {
      expertiseSearchText.value = value
    }

    /**
     * When 'Same As Email' is switched from unticked to ticked
     * - clear username field validation info
     * - update username field value to email field value
     */
    watch(usernameSameAsEmail, () => {
      if (usernameSameAsEmail.value && formRef.value) {
        formRef.value.clearValidate([FORM_ITEMS.USERNAME.NAME])
        formState[FORM_ITEMS.USERNAME.NAME] = formState[FORM_ITEMS.EMAIL.NAME] || ''
      }
    })
    /**
     * When 'Same As Email' is ticked and email filed value changed
     * - clear username field validation info
     * - update username field value to email field value
     */
    watch(
      () => formState[FORM_ITEMS.EMAIL.NAME],
      () => {
        if (usernameSameAsEmail.value && formRef.value) {
          formRef.value.clearValidate([FORM_ITEMS.USERNAME.NAME])
          formState[FORM_ITEMS.USERNAME.NAME] = formState[FORM_ITEMS.EMAIL.NAME] || ''
        }
      }
    )

    watch(
      () => props.user,
      () => {
        const newFormState = initFormData(props.user)
        Object.assign(formState, newFormState)
        profileImageUrl.value =
          props.user?.[DB_FIELDS.USER.PROFILE_PHOTO] || DEFAULT_PROFILE_PHOTO_URL
        usernameSameAsEmail.value = false
        updatePassword.value = false
      }
    )

    return {
      FORM_ITEMS,
      FORM_RULES,
      USER_ROLE_OPTIONS,
      expertiseOptions,
      filterExpertiseOption,
      formDisabled,
      formRef,
      formState,
      handleExpertiseSearch,
      isCreate,
      isSelf,
      onCancel,
      onProfileImageBeforeUpload,
      onProfileImageChange,
      onSubmit,
      profileImageUrl,
      setUpdatePassword,
      title,
      updatePassword,
      usernameSameAsEmail
    }
  }
})
</script>

<style lang="stylus">
.sz-user-form-modal
  .ant-modal-body
    max-height calc(100vh - 200px - 55px - 53px)// minus ((modal top + bottom) + header + footer)
    overflow auto

  .ant-row
    margin-bottom 5px

  .sz-user-form-username-input
    width 60%
    margin-right 10px

  .sz-user-form-profile-photo-wrapper
    margin 6px 0
    display inline-block
    position relative
    border: 1px solid #d9d9d9;

    &:before
      background-color black
      position:absolute
      z-index 1
      width 100%
      height 100%
      background-color rgba(0, 0, 0, 0.5)
      opacity 0
      -webkit-transition all 0.3s
      transition all 0.3s
      content ' '
      cursor pointer

    &:hover
      &:before
        opacity 0.4

      .sz-user-form-profile-photo-controls
        display flex

    .sz-user-form-profile-photo
      margin: 10px;
      cursor: pointer;

    .sz-user-form-profile-photo-controls
      display none
      position absolute
      top: 50%
      left: 50%
      transform translate(-50%, -50%)
      color white
      font-size 24px
      z-index 1000
      cursor pointer
      justifiy-content center
      align-items center
      .sz-user-form-profile-photo-icon
        font-size 18px
        margin-right 2px
      .sz-user-form-profile-photo-text
        font-size 20px
</style>
